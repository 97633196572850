<template>
    <styled-interface hide-toolbar>
        <styled-card>
            <template #heading>
                Search Feeds
            </template>
            <v-form
                id="search-form"
                v-model="valid"
                class="text-xs-center layout align-top search-form ma-5"
                @submit.prevent="getSearchResults">
                <v-text-field
                    v-model="searchTerms"
                    label="Search Terms"
                    :rules="requiredRules"
                    placeholder="Enter search terms..."
                    class="styled-field mr-4" />
                <styled-button
                    :loading="loading"
                    :disabled="!valid"
                    small>
                    SEARCH
                </styled-button>
            </v-form>

            <div class="ma-5">
                {{ results }}
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import StyledButton from '../globals/StyledButton';
import { requiredRules } from '../../helpers/validationRules';
import { mapState } from 'vuex';

export default {
    title: 'Feed Search',
    name: 'FeedSearch',
    components: {
        StyledInterface,
        StyledCard,
        StyledButton
    },
    data() {
        return {
            requiredRules,
            valid: false,
            searchTerms: '',
            results: '',
            loading: false,
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency
        })
    },
    methods: {
        async getSearchResults() {
            this.loading = true;

            try {
                const response = await this.$http.get(`/agencies/${this.currentAgency.id}/csv/search`, {
                    q: this.searchTerms
                });
                console.log(response);
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.search-form {
    max-width: 600px;
}
</style>
